<template>
  <div class="assortment page with_side">
    <div class="side_box" v-loading="groups.length == 0">
      <el-tree
          :data="groups"
          :props="defaultProps"
          :current-node-key="filter.group_id"
          @node-click="selectGroup"></el-tree>
    </div>
    <div class="content">
      <div class="page_header">
        <h1>Товары и услуги</h1>
        <el-button type="primary" size="mini" @click="create" icon="el-icon-plus">Создать</el-button>
      </div>
      <div id="filter">
        <div class="search_query">
          <el-input
              v-model="filter.query"
              clearable
              size="mini"
              @keyup.enter.native="changeFilter"
              @change="changeFilter"
              placeholder="Поиск наименованию или артикулу"
          ></el-input>
        </div>
      </div>
      <div class="products" v-loading="loading">
        <table class="table" v-if="products.length">
          <thead>
          <tr>
            <th></th>
            <th>Код</th>
            <th>Артикул</th>
            <th>Наименование</th>
            <th>Остаток</th>
            <th style="width: 90px; text-align: right;">Розн. цена</th>
            <th style="width: 90px; text-align: right;">Опт. цена</th>
            <th style="width: 90px; text-align: right;">Мин. цена</th>
            <th style="width: 90px; text-align: right;">Закуп. цена</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="p in products" :key="p.id" style="cursor: pointer;" @click="openProductDrawer(p)">
            <td>
              <div class="i" v-if="p.images.length">
                <el-tooltip placement="right" effect="light">
                  <div slot="content">
                    <img :src="'https://online.lbsteklo.ru/images/full/' +p.images[0].file"
                         style="max-width: 500px; display: inline-block; vertical-align: middle;"
                    />
                  </div>
                  <img :src="'https://online.lbsteklo.ru/images/tiny/' +p.images[0].file"
                       style="max-width: 100%; display: inline-block; vertical-align: middle;"
                  />
                </el-tooltip>
              </div>
            </td>
            <td>{{ p.code }}</td>
            <td>{{ p.sku }}</td>
            <td>{{ p.title }}</td>
            <td style="text-align: right;">{{ 0 | qty(p.uom.decimal_places) }} {{ p.uom.short }}</td>
            <td style="width: 90px; text-align: right;">{{ p.retail_price | price }}р</td>
            <td style="width: 90px; text-align: right;">{{ p.wholesale_base_price | price }}р</td>
            <td style="width: 90px; text-align: right;">{{ p.min_price | price }}р</td>
            <td style="width: 90px; text-align: right;">{{ p.buy_price | price }}р</td>
          </tr>
          </tbody>
        </table>
        <p v-else>Нет данных для отображения</p>

      </div>
      <div class="pagination">
        <el-pagination
            layout="prev, pager, next"
            :total="assortmentTotal"
            :page-size="filter.limit"
            :current-page="currentPage"
            :hide-on-single-page="true"
            @current-change="paginateChange"
        >
        </el-pagination>
      </div>
    </div>
    <el-drawer
        :visible.sync="drawerActive"
        direction="rtl"
        size="30%"
        custom-class="drawer_box"
        :before-close="closeDrawer"
        :destroy-on-close="true"
        :show-close="false"
        :wrapperClosable="false"
        :withHeader="false"
        :append-to-body="true"
    >
      <div class="drawer_header">
        <h3 class="title">Карточка товара / услуги</h3>
        <button class="close_btn" @click="closeDrawer">
          <i class="el-icon-close"></i>
        </button>
      </div>

      <div class="drawer_content" v-if="assortmentForm" v-loading="loading">
        <el-form :model="assortmentForm" :rules="assortmentFormRules" ref="assortmentForm" class="form"
                 @submit.native.prevent="assortmentFormAction('assortmentForm')">
          <el-form-item label="Наименование" prop="title">
            <el-input v-model="assortmentForm.title" size="mini"></el-input>
          </el-form-item>

          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="Артикул" prop="sku">
                <el-input v-model="assortmentForm.sku" size="mini"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Код" prop="code">
                <el-input v-model="assortmentForm.code" size="mini"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="Розничная цена" prop="price">
                <el-input-number
                    :min="0"
                    size="mini"
                    v-model="assortmentForm.retail_price"
                    :precision="2"
                    :step="1"
                ></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="Оптовая цена" prop="price">
                <el-input-number
                    :min="0"
                    size="mini"
                    v-model="assortmentForm.wholesale_base_price"
                    :precision="2"
                    :step="1"
                ></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="Мин. цена" prop="price">
                <el-input-number
                    :min="0"
                    size="mini"
                    v-model.number="assortmentForm.min_price"
                    :precision="2"
                    :step="1"
                ></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="Закупочная цена" prop="price">
                <el-input-number
                    :min="0"
                    size="mini"
                    v-model="assortmentForm.buy_price"
                    :precision="2"
                    :step="1"
                ></el-input-number>
              </el-form-item>
            </el-col>

          </el-row>

          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="Услуга" prop="service">
                <el-switch
                    v-model="assortmentForm.service"
                    active-color="#13ce66"
                    inactive-color="#ff4949">
                </el-switch>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="Нужен акт" prop="act_need">
                <el-switch
                    v-model="assortmentForm.act_need"
                    active-color="#13ce66"
                    inactive-color="#ff4949">
                </el-switch>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Минимальный срок поставки" prop="min_delivery_days">
                <el-input-number
                    :min="0"
                    size="mini"
                    v-model="assortmentForm.min_delivery_days"
                    :precision="0"
                    :step="1"
                ></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>


          <el-form-item label="Описание" prop="comment">
            <el-input
                type="textarea"
                :autosize="{ minRows: 2 }"
                placeholder="Описание"
                v-model="assortmentForm.description"
                size="mini"></el-input>
          </el-form-item>

          <el-form-item label="Группы" prop="groups">
            <el-tree
                :data="groups"
                show-checkbox
                node-key="id"
                ref="groupsTree"
                :default-checked-keys="assortmentForm.groups"
                :props="defaultProps"
                @check="checkFormGroup"
            >
            </el-tree>
          </el-form-item>

          <div class="images form_image_list" v-if="assortmentForm.images">
            <div class="image" v-for="image in assortmentForm.images" :key="image.id"
                 style="display: inline-block; margin-right: 10px;">
              <el-button
                  class="delete"
                  type="danger"
                  icon="el-icon-delete"
                  size="mini"
                  @click="deleteImage(image)"
                  circle
              ></el-button>
              <el-image
                  :src="'https://online.lbsteklo.ru/images/full/'+image.file"
                  style="width: 150px; height: 150px;"
                  fit="cover"
              ></el-image>
            </div>
            <br>
          </div>

          <el-upload
              v-if="assortmentForm.id"
              class="uploader"
              :action="'https://online.lbsteklo.ru/assortment/uploadImage/'+assortmentForm.id"
              multiple
              :limit="10"
              :show-file-list="false"
              :headers="{Authorization: getLocalToken()}"
              :on-success="successFileUpload">
            <el-button size="mini" type="primary">Загрузить изображения</el-button>
            <div slot="tip" class="el-upload__tip">Файлы в формате jpg/png размером не более 2Mb</div>
          </el-upload>
          <br>

          <el-form-item>
            <el-button
                type="primary"
                size="mini"
                @click="assortmentFormAction('assortmentForm')">Сохранить
            </el-button>
            <el-button size="mini" @click="closeDrawer">Отменить</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import {actionTypes as assortmentActions} from "@/store/modules/assortment";

import {getItem} from "@/helpers/persistanceStorage";

import price from "@/filters/price";
import qty from "@/filters/qty";

export default {
  name: "Assortment",
  data() {
    return {
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      filter: {
        limit: this.$route.query.limit ? Number(this.$route.query.limit) : 50,
        offset: this.$route.query.offset ? Number(this.$route.query.offset) : 0,
        query: this.$route.query.query ? this.$route.query.query : '',
        group_id: this.$route.query.group_id ? Number(this.$route.query.group_id) : 0,
      },
      assortmentForm: null,
      drawerActive: false,
      uploadIcon: 'el-icon-upload',
      assortmentFormRules: {
        title: [
          {required: true, message: 'Наименование', trigger: 'blur'}
        ]
      }
    }
  },
  computed: {
    ...mapState({
      products: state => state.assortment.products,
      groups: state => state.assortment.groups,
      loading: state => state.assortment.loading,
      submitting: state => state.assortment.submitting,
      assortmentTotal: state => state.assortment.total,
    }),
    currentPage() {
      return ((this.filter.offset + this.filter.limit) / this.filter.limit)
    },
  },
  methods: {
    create() {
      this.drawerActive = true
      this.assortmentForm = {
        act_need: false,
        buy_price: 0,
        code: '',
        description: '',
        min_delivery_days: 0,
        min_price: 0,
        retail_price: 0,
        service: false,
        sku: '',
        title: '',
        uom_id: 2,
        wholesale_base_price: 0,
      }
    },
    loadGroups() {
      this.$store.dispatch(assortmentActions.getGroups)
    },
    loadProducts() {
      this.$store.dispatch(assortmentActions.getProducts, this.filter)
    },
    loadAssortment() {
      this.loadProducts()
      this.loadGroups()
    },
    clear() {
      this.$store.dispatch(assortmentActions.clear)
    },
    selectGroup(group) {
      this.filter.group_id = group.id
      this.filter.offset = 0
      this.changeRoute();
      this.loadProducts()
    },
    changeFilter() {
      this.filter.offset = 0
      this.changeRoute();
      this.loadProducts()
    },
    openProductDrawer(p) {
      this.drawerActive = true
      this.assortmentForm = _.cloneDeep(p)
    },
    closeDrawer() {
      this.drawerActive = false
      this.assortmentForm = null
      this.uploadIcon = 'el-icon-upload'
    },
    assortmentFormAction(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$store.dispatch(assortmentActions.saveProduct, this.assortmentForm).then(() => {
            this.loadProducts()
            this.drawerActive = false
            this.assortmentForm = null
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      })
    },
    getLocalToken() {
      const token = getItem('accessToken')
      return token ? `Token ${token}` : ''
    },
    successFileUpload(image) {
      console.log(image)
      this.assortmentForm.images.push(image)
    },
    paginateChange(page) {
      this.filter.offset = (page - 1) * this.filter.limit;
      this.changeRoute();
      this.loadProducts();
    },
    changeRoute() {
      this.$router
          .push({path: 'assortment', query: this.filter})
          .catch((e) => {
            console.log('route not changed')
          })
    },
    checkFormGroup() {
      this.assortmentForm.groups = this.$refs.groupsTree.getCheckedKeys()
    },
    deleteImage(image) {
      this.assortmentForm.images = _.remove(this.assortmentForm.images, function (i) {
        return i.id !== image.id
      })
      this.$store.dispatch(assortmentActions.removeImage, image.id).then(() => {
        this.loadProducts()
      })
    }
  },
  mounted() {
    this.loadAssortment()
  },
  beforeDestroy() {
    this.clear()
  },
  filters: {
    price, qty
  },
  created() {
    document.title = this.$route.meta.title ? this.$route.meta.title : 'GlassERP'
  }
}
</script>
